<template>

  <div class="chart">

    <vue-highcharts
      ref="statGraph"
      type="chart"
      :options="chartOptions"
      :redrawOnUpdate="true"
      :oneToOneUpdate="false"
      :animateOnUpdate="true"
      @rendered="onRender"/>

    <div>
     <b>Total impressions:</b> {{ total_impressions }}
     <br />
     <b>Total clicks:</b> {{ total_clicks }}
     <br />
     <b>Avg CTR(%):</b> {{ (total_impressions && total_clicks ? (total_clicks*100/total_impressions).toFixed(2) : 0) }}
    </div>

  </div>

</template>

<script setup>

import VueHighcharts from 'vue3-highcharts';

const dt = ref([])
const views = ref([])
const clicks = ref([])
const total_impressions = ref(0)
const total_clicks = ref(0)
const statGraph = ref(null)

const props = defineProps({
  data: {
    type: Object    
  }
});


const chartOptions = ref({
  chart: {
    type: 'line'
  },

  title: {
    text: ''//chart_name
  },

  credits: {
    enabled: false
  },

  xAxis: {
      categories: [],
      crosshair: true,
      labels: {
        formatter() {
          //return Highcharts.dateFormat('%e. %b',Date.parse(this.value))
          return formatDate(this.value)
        }
      },
      color: '#ff0000',
  },

  yAxis: [
  {
      min: 0,
      title: {
          text: ''
      },
      allowDecimals: false
  },
  {
      min: 0,
      title: {
          text: ''
      },
      allowDecimals: false,
      opposite: true,
  }],

  tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                   '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
  },

  plotOptions: {
    column: {
        pointPadding: 0.1,
        borderWidth: 0
    }
  },

  series: [
    {
      name: 'Impressions',
      data: []
    },
    {
      name: 'Clicks',
      data: [],
      yAxis: 1,
      color: '#000000'
    }
  ]
});

const data = ref(props.data)

// if(data.value !== null) {
//   dt.value = Object.keys(data.value);
//   views.value = Object.values(data.value).map(e => e.views);
//   clicks.value = Object.values(data.value).map(e => e.clicks);
// }

// chartOptions.value.xAxis.categories = dt.value;
// chartOptions.value.series[0].data = views.value;
// chartOptions.value.series[1].data = clicks.value;

// total_impressions.value = views.value.reduce(function(a, b) { return a + b; }, 0);
// total_clicks.value = clicks.value.reduce(function(a, b) { return a + b; }, 0);

const setData = (data) => {
  dt.value = Object.keys(data);
  views.value = Object.values(data).map(e => e.views);
  clicks.value = Object.values(data).map(e => e.clicks);

  chartOptions.value.xAxis.categories = dt.value;
  chartOptions.value.series[0].data = views.value;
  chartOptions.value.series[1].data = clicks.value;

  total_impressions.value = views.value.reduce(function(a, b) { return a + b; }, 0);
  total_clicks.value = clicks.value.reduce(function(a, b) { return a + b; }, 0);
}

const formatDate = (date) => {
  date = new Date(date);
  var strArray=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var d = date.getDate();
  var m = strArray[date.getMonth()];
  return '' + d + '.' + m;
}

// const nextTick = () => {
//   // statGraph.value.chart.reflow();
// }

setData(data.value);

onMounted(() => {
  // nextTick();
  //console.log('tikkkkks');
  setData(data.value);
})

watch(() => props.data, (new_data, old_data) => {
   data.value = new_data;
  //  console.log('watcher');
  //  console.log(new_data);
  setData(data.value);

   statGraph.value.chart.reflow();
}, {deep: true});

</script>

<style>

.chart {
  width:100%;
}

.chart >>> .vue-highcharts {
  width:100%;
  height: 250px;
}

.chart > div {
  width: 100%;
}


</style>